import { useEffect } from 'react';
import GET_RECOMMENDATION_BY_PARAM from 'src/graphql/mutations/getRecommendationByParam.gql';
import useRESTMutation from 'src/hooks/useRESTMutation';
import useTranslation from 'src/hooks/useTranslation';

import useApiHit from 'src/hooks/useApiHit';

import * as S from './styles';

import { uiComponentVariant } from 'src/enums/uiComponentVariant';
import AddToBasketControls from 'src/components/AddToBasketControls';

export const ProductRecommendation = ({
  recommendationId,
  productPageSlug,
  productPageLayout,
  onnTopHomePageLayout,
  onnTopHomePageSlug,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [getRecommendation, { data: recommendationData, loading }] = useRESTMutation(GET_RECOMMENDATION_BY_PARAM, {
    variables: {
      param: recommendationId,
      limit: 1,
      shuffle: 0,
      page: 0,
    },
  });

  const { hit } = useApiHit();

  useEffect(() => {
    getRecommendation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!recommendationData || loading) {
    return null;
  }

  const recommendations = recommendationData?.recommendation?.items;
  const recommendation = recommendations?.[0];

  if (!recommendation) {
    return null;
  }

  const { brand, name, imageth, price, unit, slug, onntop, onntopcb, avail } = recommendation;

  const promotionType = price?.promo || 0;

  const productSlug = `/${productPageSlug}/${slug}`;
  const productLinkProps = { as: productSlug };
  const shouldDisplayStockInfo = !!avail?.quantitydc || !!avail?.description;
  const priceItem = price.items[0];

  const brandPhoto = brand?.[0]?.logourl;
  const brandName = brand?.[0]?.name;

  return (
    <S.ProductRecommendation {...restProps}>
      <S.ProductRecommendationMainContent>
        <S.Link
          href={productPageLayout}
          variant={uiComponentVariant.QUATERNARY}
          isNextLink
          nextLinkProps={productLinkProps}
          onClick={() => {
            hit({
              rid: recommendation?.rid,
              productid: recommendation?.id,
            });
          }}
        >
          <S.ProductThumbAndNameWrapper>
            {brandPhoto ? <S.BrandThumb src={brandPhoto} alt={brandName} /> : null}
            <S.ThumbAndOnnTopWrapper>
              <S.Thumb src={imageth} alt={name} width={123} height={122} />

              <S.OnnTopMarkers
                onnTopKey={onntop}
                cashbackKey={onntopcb}
                onnTopHomePageLayout={onnTopHomePageLayout}
                onnTopHomePageSlug={onnTopHomePageSlug}
              />
            </S.ThumbAndOnnTopWrapper>
          </S.ProductThumbAndNameWrapper>
        </S.Link>

        <S.ProductPriceAndBasketInputWrapper>
          <S.ProductName>{name}</S.ProductName>
          <div>
            <S.PriceComponent
              crossedPrice={priceItem.pricecross}
              price={priceItem.priceend}
              unit={unit}
              isFirstPriceOnTheList={true}
              variant={uiComponentVariant.SECONDARY}
              promoCode={promotionType}
              lastPrice={priceItem.lastprice}
              currencySign={recommendationData?.recommendation?.items?.[0]?.price?.currsig}
            />

            {shouldDisplayStockInfo ? (
              <S.StockInfo>
                {avail?.quantitydc > 0 ? (
                  <S.AvailabilityStatus>
                    {avail.quantitydc} {unit}
                  </S.AvailabilityStatus>
                ) : (
                  <span>{avail?.description || t('partsOutOfStock')}</span>
                )}
              </S.StockInfo>
            ) : null}
            <AddToBasketControls
              id={recommendation?.id}
              name={recommendation?.name}
              category={recommendation?.categories?.[0]?.name}
              price={recommendation?.price.items[0]?.priceseo}
              brand={recommendation?.brand[0]?.name}
              sku={recommendation?.index}
              nowInBasket={recommendation?.avail?.quantityinbasket}
              isPartial={recommendation?.ispartial}
              step={priceItem?.quantity}
              recommendationId={recommendation?.rid}
              min={recommendation?.avail?.quantitylc}
              max={recommendation?.avail?.quantitydc}
              productImgSrc={recommendation?.imageth}
              boughtBefore={recommendation?.purchase}
            />
          </div>
        </S.ProductPriceAndBasketInputWrapper>
      </S.ProductRecommendationMainContent>
    </S.ProductRecommendation>
  );
};
