import * as S from './styles';
import Icon from '../Icon';

const defaultProps = {
  selected: false,
  hasChildren: false,
  size: 0,
  variant: 'primary',
  nextLinkProps: {},
};
export const CategoriesNamesListItemLink = ({
  name,
  size,
  onClick,
  selected,
  hasChildren,
  variant,
  showIcon,
  nextLinkProps,
  ...restProps
} = defaultProps) => {
  const selectedSecondaryItem = variant === 'secondary' && selected;

  let childrenElement;
  if (typeof name === 'string') {
    // NOTE: I don't have any idea why we catch '/' and add space before and after.
    childrenElement = name.replace(/\s*(\/)\s*/g, ' $1 ');
  } else {
    childrenElement = name;
  }

  return (
    <S.CategoriesNamesItem {...restProps}>
      <S.CategoriesNamesItemLink variant={variant} selected={selected} {...nextLinkProps}>
        <S.CategoriesNamesItemButtonText>{childrenElement}</S.CategoriesNamesItemButtonText>
        <S.CategoryQuantityAndIconWrapper>
          {(!!size || size === 0) && <S.CategoriesNamesItemSize>{size}</S.CategoriesNamesItemSize>}
          <S.CategoriesNamesItemButtonIconPlace variant={variant}>
            {(hasChildren || selectedSecondaryItem) && showIcon && <Icon icon="arrowRight" />}
          </S.CategoriesNamesItemButtonIconPlace>
        </S.CategoryQuantityAndIconWrapper>
      </S.CategoriesNamesItemLink>
    </S.CategoriesNamesItem>
  );
};

CategoriesNamesListItemLink.defaultProps = defaultProps;
