import * as S from './styles';

const defaultProps = {
  variant: 'primary',
  horizontal: false,
};

export const BulletList = ({ variant, children, horizontal, ...restProps } = defaultProps) => (
  <S.BulletList variant={variant} horizontal={horizontal} {...restProps}>
    {children}
  </S.BulletList>
);

BulletList.defaultProps = defaultProps;
