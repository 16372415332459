import styled from 'styled-components';
import { rem } from 'polished';

import _AddToBasketInput from 'src/components/AddToBasketInput';
import _AddToBasketButton from 'src/components/AddToBasketButton';
import _Link from 'src/components/Link';

export const AddToBasketButton = styled(_AddToBasketButton)`
  padding-top: ${rem(6)};
  padding-bottom: ${rem(6)};

  width: ${rem(73)};
`;

export const SelectPartLinkWrapper = styled.div`
  text-align: center;
  text-transform: lowercase;
  margin-top: ${rem(6)};
`;

export const SelectPartLink = styled(_Link)`
  text-decoration: underline;
  font-size: ${({ theme }) => theme.typography.fontSize.s2};
`;

export const AddToBasketInput = styled(_AddToBasketInput)`
  gap: 0;
  .inputBox {
    height: ${rem(34)};
    width: ${rem(56)};
    box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.defaultPalette.monochrome[3]};
  }
  > button {
    height: ${rem(25)};
    min-height: ${rem(25)};
    width: ${rem(25)};
    min-width: ${rem(25)};
    background-color: ${({ theme }) => theme.defaultPalette.monochrome[1]};
  }
  .minus {
    width: ${rem(34)};
    height: ${rem(34)};
    margin-right: ${rem(-6)};
  }
  .plus {
    width: ${rem(34)};
    height: ${rem(34)};

    margin-left: ${rem(-6)};
  }
  & .minus:hover,
  .plus:hover {
    background-color: ${({ theme }) => theme.defaultPalette.teal[0]};
    color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  }

  & .input {
    font-size: ${({ theme }) => theme.typography.size.s2};
    height: 100%;
    min-width: 40px;
  }
`;

export const AddToBasketButtonAndInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-left: auto;
  width: auto;

  justify-content: flex-end;
  gap: ${rem(5)};
  input {
    padding: ${rem(4)}!important;
    height: ${rem(31)};
  }

  & .add-to-basket-tooltip > button {
    height: ${rem(33)};
    padding-top: ${rem(4)};
    padding-bottom: ${rem(4)};
    padding-left: ${rem(12)};
    padding-right: ${rem(12)};
  }

  div > button .content {
  }
`;
