import styled from 'styled-components';
import { em, rem } from 'polished';

import Text from '../Text';
import ImportedOnnTopMarkers from '../OnnTopMarkers';
import _AddToBasketInput from 'src/components/AddToBasketInput';
import _AddToBasketButton from 'src/components/AddToBasketButton';
import _Link from 'src/components/Link';
import _PriceComponent from 'src/components/PriceComponent';

export const ProductRecommendation = styled.div`
  width: 100%;
  display: flex;
  
  align-items: center;
  border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[8]};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  /* max-width: ${rem(410)}; */
  width: fit-content;
  border-radius: ${rem(30)};
  
  position: relative;
  
`;

export const Thumb = styled.img`
  display: block;

  max-width: 100%;
  object-fit: contain;
  max-width: ${rem(144)};

  max-height: ${rem(142)};
`;

export const BrandThumb = styled.img`
  display: block;
  object-fit: contain;
  max-height: 3rem;
  max-width: ${rem(110)};
  margin-right: auto;
  margin-bottom: ${rem(25)};
`;

export const Promotion = styled.span`
  color: ${(p) => p.theme.defaultPalette.monochrome[4]};
  font-size: ${({ theme }) => theme.typography.fontSize.m2};
  /* padding: ${(p) => (p.isSmall ? '0.125em 0.5em' : '0.25em 0.75em')}; */
  /* margin: ${(p) => p.isSmall && '0 auto'}; */
  width: auto;

  pointer-events: none;
`;

export const ProductHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${rem(32)};

  justify-content: ${({ promotion }) => (promotion ? 'space-between' : 'flex-end')};
  width: 100%;
  /* margin-bottom: 1em; */
`;

export const ProductName = styled(Text)`
  margin-bottom: ${rem(10)};
  max-width: ${rem(180)};

  text-align: center;

  &:hover {
    color: inherit;
  }
`;

export const ProductPriceAndBasketInputWrapper = styled.div`
  display: flex;
  /* width: 100%; */
  border-radius: ${rem(30)};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[9]};

  flex-direction: column;
  justify-content: space-between;
  padding: ${rem(10)} ${rem(20)};
  /* margin-bottom: ${em(10)}; */
  /* height: ${rem(158)}; */

  /* margin: auto; */
`;

export const ProductPrice = styled.div`
  align-self: flex-end;
  text-align: center;
`;

export const ProductPriceContent = styled.div`
  text-align: center;
`;

export const ProductPriceEnd = styled(Text)`
  ${(p) => p.theme.helpers.fontFamilyWithFallback(p.theme.typography.type.tertiary)};
  color: ${(p) => p.theme.price.color[p.priceVariant]};
  margin-bottom: 0;
`;

export const ProductPriceDetails = styled(Text)`
  color: ${(p) => p.theme.defaultPalette.monochrome[3]};
  margin-bottom: 0;
`;

export const CatalogPrice = styled(Text)`
  color: ${(p) => p.theme.defaultPalette.monochrome[3]};
  margin-top: 0.625em;
  margin-bottom: 0;
  text-align: center;
  text-decoration: line-through;
`;

export const OnnTopMarkers = styled(ImportedOnnTopMarkers)`
  /* padding-top: 1em; */
  position: absolute;
  top: -10px;

  width: 100%;
  display: flex;
  justify-content: center;

  img {
    height: ${rem(50)};
  }
`;

export const ProductRecommendationMainContent = styled.div`
  /* width: 100%; */
  display: flex;
  /* min-height: ${rem(222)}; */

  gap: ${rem(6)};
  /* align-items: center; */
  /* position: relative; */
`;

export const ProductThumbAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  align-items: center;

  /* min-height: ${rem(222)}; */

  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
`;

export const ThumbAndPriceWrapper = styled.div`
  display: flex;
  margin-left: ${rem(15)};
  gap: ${rem(10)};
`;

export const AddToBasketInput = styled(_AddToBasketInput)`
  gap: 0;
  .inputBox {
    height: ${rem(34)};
    width: ${rem(56)};
    box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.defaultPalette.monochrome[3]};
  }
  > button {
    height: ${rem(25)};
    min-height: ${rem(25)};
    width: ${rem(25)};
    min-width: ${rem(25)};
    background-color: ${({ theme }) => theme.defaultPalette.monochrome[1]};
  }
  .minus {
    width: ${rem(34)};
    height: ${rem(34)};
    margin-right: ${rem(-6)};
  }
  .plus {
    width: ${rem(34)};
    height: ${rem(34)};

    margin-left: ${rem(-6)};
  }
  & .minus:hover,
  .plus:hover {
    background-color: ${({ theme }) => theme.defaultPalette.teal[0]};
    color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  }

  & .input {
    font-size: ${({ theme }) => theme.typography.size.s2};
    height: 100%;
    min-width: 40px;
  }
`;

export const AddToBasketButton = styled(_AddToBasketButton)`
  padding-top: ${rem(6)};
  padding-bottom: ${rem(6)};

  width: ${rem(73)};
`;

export const AddToBasketButtonAndInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-left: auto;
  width: auto;

  justify-content: flex-end;
  gap: ${rem(5)};
  input {
    padding: ${rem(4)}!important;
    height: ${rem(31)};
  }

  & .add-to-basket-tooltip > button {
    height: ${rem(33)};
    padding-top: ${rem(4)};
    padding-bottom: ${rem(4)};
    padding-left: ${rem(12)};
    padding-right: ${rem(12)};
  }
`;

export const StockInfo = styled.div`
  font-size: ${({ theme }) => theme.typography.size.s2};
  margin-bottom: ${rem(5)};
  display: flex;
  justify-content: center;
`;

export const AvailabilityStatus = styled.span`
  &:before {
    content: ' ';
    display: inline-block;
    width: ${rem(9)};
    height: ${rem(9)};
    border: 1px solid ${({ theme }) => theme.defaultPalette.chateauGreen[0]};
    margin-right: ${rem(4)};

    border-radius: 50%;

    border-width: 1px;

    margin-top: 0;

    background-color: ${({ theme }) => theme.defaultPalette.chateauGreen[0]};
  }
`;

export const Link = styled(_Link)`
  padding: ${rem(10)} 0 ${rem(10)} ${rem(16)};
`;

export const ThumbAndOnnTopWrapper = styled.div`
  position: relative;
`;

export const PriceComponent = styled(_PriceComponent)`
  margin-bottom: ${rem(15)};
`;
