import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { em, rem } from 'polished';

import { breakpointsKeys } from 'src/enums/breakpoints';

import Button from 'src/components/Button';
import Link from 'src/components/Link';

const getVariantTheme = (theme, variant) =>
  ({
    primary: {
      iconColor: theme.defaultPalette.blazeOrange[0],
      active: {
        bg: theme.defaultPalette.monochrome[0],
        color: theme.defaultPalette.monochrome[4],
        fontFamily: theme.typography.type.tertiary,
      },
    },
    secondary: {
      iconColor: theme.defaultPalette.blazeOrange[1],
      active: {
        bg: theme.defaultPalette.teal[0],
        color: theme.defaultPalette.monochrome[0],
        fontFamily: theme.typography.type.primary,
      },
    },
    tertiary: {
      iconColor: theme.defaultPalette.monochrome[0],
      active: {
        bg: theme.defaultPalette.monochrome[0],
        color: theme.defaultPalette.monochrome[4],
        fontFamily: theme.typography.type.primary,
      },
    },
  }[variant]);

export const CategoriesNamesList = styled.nav`
  position: relative;
  margin-top: ${(p) => !p.title && '0.5em'};
`;

export const CategoriesNamesListItems = styled.ul`
  display: block;

  background: ${(p) => p.theme.defaultPalette.monochrome[0]};

  & > li {
    ${(p) => p.theme.helpers.transition()};
    background: ${(p) => p.theme.defaultPalette.monochrome[1]};

    display: flex;
    justify-content: space-between;
    align-items: center;

    & + li {
      margin-top: ${rem(3)};
    }
  }
`;

export const CategoriesNamesItem = styled.li`
  position: relative;
  border-radius: ${rem(10)};
`;

export const CategoriesNamesItemLink = styled(Link).attrs(() => ({
  blank: true,
}))`
  ${({ theme }) => theme.helpers.transition(['background-color, border, outline'])};
  border-radius: ${rem(10)};
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.defaultPalette.monochrome[3] : theme.defaultPalette.monochrome[8])};

  outline: ${({ theme, selected }) => selected && `1px solid ${theme.defaultPalette.monochrome[3]}`};
  ${({ selected }) => selected && 'pointer-events: none'};

  padding: ${rem(8)} ${rem(12)} ${rem(8)} ${rem(15)};
  line-height: 1em;
  flex-grow: 1;
  text-align: left;

  font-family: ${(p) => (p.selected ? getVariantTheme(p.theme, p.variant).active.fontFamily : 'inherit')};

  background-color: ${(p) =>
    p.selected ? getVariantTheme(p.theme, p.variant).active.bg : p.theme.defaultPalette.monochrome[9]};

  color: ${(p) => (p.selected ? getVariantTheme(p.theme, p.variant).active.color : 'inherit')};
  width: 100%;

  &:hover,
  &:active,
  &:focus,
  &.active {
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[3]};
    outline: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[3]};

    background-color: ${(p) => getVariantTheme(p.theme, p.variant).active.bg};
    color: ${(p) => getVariantTheme(p.theme, p.variant).active.color};
  }

  & > .content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }
`;
export const CategoriesNamesItemButton = styled(Button).attrs(() => ({
  blank: true,
}))`
  border-radius: ${rem(12)};
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.defaultPalette.monochrome[3] : theme.defaultPalette.monochrome[8])};

  padding: ${rem(10)} ${rem(12)} ${rem(10)} ${rem(15)};

  line-height: 1em;
  flex-grow: 1;
  text-align: left;

  font-family: ${(p) => (p.selected ? getVariantTheme(p.theme, p.variant).active.fontFamily : 'inherit')};

  background-color: ${(p) =>
    p.selected ? getVariantTheme(p.theme, p.variant).active.bg : p.theme.defaultPalette.monochrome[9]};

  color: ${(p) => (p.selected ? getVariantTheme(p.theme, p.variant).active.color : 'inherit')};
  width: 100%;

  &:hover,
  &:active,
  &.active {
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[3]};
    background-color: ${(p) => getVariantTheme(p.theme, p.variant).active.bg};
    color: ${(p) => getVariantTheme(p.theme, p.variant).active.color};
  }
  transition: background-color 0.5s;
  & > .inner {
    justify-content: flex-start;

    & > .content {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }
  }
`;

export const CategoriesNamesItemButtonIconPlace = styled.span`
  display: flex;
  > * {
    vertical-align: unset;
  }

  ${(p) => {
    if (p.variant === 'secondary') {
      return css`
        position: absolute;
        width: ${em(16)};
        height: ${em(16)};
        font-size: ${em(16)};
        right: 1rem;
        display: flex;
        align-items: center;
        color: ${({ theme }) => getVariantTheme(theme, p.variant).iconColor};
      `;
    }

    return css`
      min-width: 1.5em;
      width: 1.5em;
      display: flex;
      max-width: 1.5em;
      padding-left: 0.5em;
      font-size: ${rem(12)};
      align-items: center;
      height: 100%;

      color: ${({ theme }) => theme.defaultPalette.monochrome[3]};
    `;
  }};
  ${up(breakpointsKeys.TABLET)} {
    display: none;
  }
`;

export const CategoriesNamesItemButtonText = styled.span`
  flex-grow: 1;
  word-wrap: anywhere;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
`;

export const CategoriesNamesItemSize = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSize.s2};

  color: ${({ theme }) => theme.defaultPalette.monochrome[3]};
  display: flex;
  height: fit-content;
  align-items: center;
`;

export const CategoryQuantityAndIconWrapper = styled.div`
  display: flex;
  padding-top: ${rem(1)};
  margin-left: ${rem(4)};
  align-items: center;
`;
