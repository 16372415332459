import styled, { css } from 'styled-components';
import { up, only } from 'styled-breakpoints';
import { rem, em } from 'polished';
import { breakpointsKeys } from 'src/enums/breakpoints';

import _LastPriceInfo from 'src/components/LastPriceInfo';
import { uiComponentVariant } from 'src/enums/uiComponentVariant';

export const PriceValue = styled.span`
  font-family: ${({ theme }) => theme.global.fontFamily.primary};
  ${({ theme, isFirstPriceOnTheList }) => isFirstPriceOnTheList && `font-family: ${theme.global.fontFamily.tertiary}`};

  ${({ isFirstPriceOnTheList, theme }) =>
    isFirstPriceOnTheList
      ? `font-size: ${theme.typography.fontSize.s3}`
      : `font-size: ${theme.typography.fontSize.s2}`};

  ${up(breakpointsKeys.TABLET)} {
    white-space: nowrap;
    ${({ theme, isFirstPriceOnTheList }) =>
      isFirstPriceOnTheList && `font-family: ${theme.global.fontFamily.secondary}`};
    ${({ isFirstPriceOnTheList, theme }) =>
      isFirstPriceOnTheList
        ? `font-size: ${theme.typography.fontSize.s3}`
        : `font-size: ${theme.typography.fontSize.s1}`};
    ${({ isFirstPriceOnTheList, theme }) => !isFirstPriceOnTheList && `color: ${theme.defaultPalette.monochrome[3]}`};
  }
`;

export const LastPriceInfo = styled(_LastPriceInfo)`
  ${({ separateLines }) => separateLines && 'text-align: left'};
  ${only(breakpointsKeys.TABLET)} {
    white-space: break-spaces;
    text-align: left;
  }
`;

export const CrossedPrice = styled.div`
  text-decoration: line-through;
  color: ${(p) => p.theme.price.color.normal};
  white-space: nowrap;
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.productPricesList.fontSize.crossedPrice)};
  line-height: ${em(16)};
`;

export const PriceUnit = styled.span`
  &::before {
    content: ' / ';
  }
`;

export const PriceAndCrossedPriceWrapper = styled.div``;

const getStylesForSecondaryVariant = () => css`
  width: 100%;
  ${CrossedPrice} {
    font-size: ${({ theme }) => theme.typography.fontSize.xs3};
  }
  ${PriceValue} {
    font-size: ${({ theme }) => theme.typography.fontSize.m2};
    font-family: ${({ theme }) => theme.global.fontFamily.tertiary};
  }
  ${PriceAndCrossedPriceWrapper} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  ${LastPriceInfo} {
    margin-top: 0;
    text-align: center;
  }
`;

export const Price = styled.div`
  ${({ theme }) => theme.helpers.fontFamilyWithFallback(theme.typography.type.primary)};
  ${({ theme }) => theme.helpers.dynamicFontSize(theme.productPricesList.afterPricePiece)};
  color: ${({ theme, priceVariant }) => theme.productPricesList.colors[priceVariant]};
  position: relative;
  text-align: left;

  grid-area: price;
  align-self: ${({ withLastPrice }) => (withLastPrice ? 'flex-start' : 'flex-end')};

  line-height: ${rem(26.5)};
  ${up(breakpointsKeys.TABLET)} {
    line-height: ${rem(24)};
  }
  ${({ variant }) => variant === uiComponentVariant.SECONDARY && getStylesForSecondaryVariant()};
`;
