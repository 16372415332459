import { useState, useRef, useEffect } from 'react';
import { classNamesOfLayout } from 'src/enums/classNamesOfLayout';
import { breakpointsKeys } from 'src/enums/breakpoints';
import { getElementSize } from 'src/utils/getElementSize';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import ProductRecommendation from '../ProductRecommendation';

import { createNextLinkProps } from 'src/utils/createNextLinkProps';

import ProducersList from '../ProducersList';

import * as S from './styles';

const INACTIVE_ITEM_IDX = -1;
const DELAY = 150;

const defaultProps = {
  contentUnderCategories: null,
  mainContent: null,
  hoverVersion: false,
  visibleOnHover: false,
};

const calculateColumnsQuantity = (items) => {
  switch (true) {
    case items.length >= 18:
      return 3;
    case items.length >= 10:
      return 2;
    case items.length < 10:
      return 1;
    default:
      return 1; // This default case handles any unexpected input
  }
};

export const CategoriesColumnWithContent = ({
  categories,
  contentUnderCategories,
  mainContent,
  productsPageLayout,
  productPageSlug,
  productPageLayout,
  brandPageSlug,
  brandPageLayout,
  onnTopHomePageLayout,
  onnTopHomePageSlug,
  hoverVersion,
  visibleOnHover,
  ...restProps
} = defaultProps) => {
  const categoriesListRef = useRef();
  const categoriesNamesListRef = useRef();
  const timeoutRef = useRef(null);
  const categoriesMouseOverRef = useRef(null);
  const [bgHeight, setBgHeight] = useState(0);
  const [categoriesNamesListHeight, setCategoriesNamesListHeight] = useState(0);
  const [isPossibleToShowDetails, setIsPossibleToShowDetails] = useState(false);
  const [activeItemIdx, setActiveItemIdx] = useState(INACTIVE_ITEM_IDX);
  const isCategoryViewInactive = !isPossibleToShowDetails || activeItemIdx === INACTIVE_ITEM_IDX;

  const clearCategoriesMouseOverTimeoutRef = () => {
    if (categoriesMouseOverRef.current) {
      clearTimeout(categoriesMouseOverRef.current);
    }
  };
  const handleCategoriesColumnWithContentMouseOut = () => {
    setActiveItemIdx(INACTIVE_ITEM_IDX);
    clearCategoriesMouseOverTimeoutRef();
  };

  const handleCategoryNameMouseOver = (itemIdx) => {
    setBgHeight(getElementSize(categoriesListRef.current));
    clearCategoriesMouseOverTimeoutRef();
    categoriesMouseOverRef.current = setTimeout(() => setActiveItemIdx(itemIdx), DELAY);
  };

  const assignTimeoutRef = () => {
    timeoutRef.current = setTimeout(() => {
      setIsPossibleToShowDetails(true);
    }, DELAY);
  };

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleListMouseEnter = () => {
    if (!isPossibleToShowDetails) {
      setIsPossibleToShowDetails(false);
    }
    assignTimeoutRef();
  };

  const handleListMouseLeave = () => {
    setIsPossibleToShowDetails(false);
    clearTimeoutRef();
  };

  const handleCategoryDetailsMouseEnter = () => {
    setIsPossibleToShowDetails(true);
    clearCategoriesMouseOverTimeoutRef();
    clearTimeoutRef();
  };

  const handleCategoryDetailsFillerMouseEnter = () => {
    setIsPossibleToShowDetails(false);
    clearTimeoutRef();
  };

  useEffect(() => {
    if (hoverVersion && categoriesNamesListRef?.current) {
      setBgHeight(getElementSize(categoriesListRef.current));
    }
  }, [categoriesNamesListRef, hoverVersion]);

  useEffect(() => {
    if (categoriesNamesListRef?.current) {
      setCategoriesNamesListHeight(categoriesNamesListRef.current.clientHeight);
    }
  }, [categoriesNamesListRef, categoriesNamesListRef?.current?.clientHeight]);

  const currentBreakpoint = useBreakpointDetector();
  const isMobileView = currentBreakpoint === breakpointsKeys.MOBILE;

  if (isMobileView) return null;

  return (
    <>
      <S.CategoriesColumnWithContent
        ref={categoriesListRef}
        bgVisible={hoverVersion || (!isCategoryViewInactive && !hoverVersion)}
        bgHeight={bgHeight}
        hoverVersion={hoverVersion}
        visibleOnHover={visibleOnHover}
        {...restProps}
      >
        <S.CategoriesColumnWithContentGrid onMouseLeave={handleCategoriesColumnWithContentMouseOut}>
          <S.CategoriesDetailsWrapper hoverVersion={hoverVersion}>
            <S.CategoryDetails visible={!isCategoryViewInactive}>
              {categories.map(({ name, slug, recommendation, brands, children }, categoryIdx) => {
                const contentKey = `category_content_${slug}`;

                const columnsCount = calculateColumnsQuantity(children);

                const isContentVisible = categoryIdx === activeItemIdx;

                return (
                  <S.CategoryDetailsInner
                    key={contentKey}
                    visible={isContentVisible}
                    minHeight={categoriesNamesListHeight}
                    onMouseEnter={handleCategoryDetailsMouseEnter}
                    hoverVersion={hoverVersion}
                  >
                    <S.CategoryDetailsPrimary>
                      <S.CategoryDetailsSubcategories>
                        <S.Heading variant="secondary" component={hoverVersion ? 'p' : 'h2'}>
                          {name}
                        </S.Heading>

                        <S.CategoryDetailsBulletList columnsCount={columnsCount}>
                          {children.map(({ name, size, slug }) => {
                            const nextLinkProps = createNextLinkProps(slug, productsPageLayout);

                            return (
                              <S.BulletListItemLink name={name} size={size} key={slug} nextLinkProps={nextLinkProps} />
                            );
                          })}
                        </S.CategoryDetailsBulletList>
                      </S.CategoryDetailsSubcategories>

                      {isContentVisible && recommendation ? (
                        <ProductRecommendation
                          recommendationId={recommendation}
                          productPageSlug={productPageSlug}
                          productPageLayout={productPageLayout}
                          onnTopHomePageLayout={onnTopHomePageLayout}
                          onnTopHomePageSlug={onnTopHomePageSlug}
                        />
                      ) : null}
                    </S.CategoryDetailsPrimary>

                    <S.CategoryDetailsBrands>
                      {isContentVisible && !!brands.length > 0 ? (
                        <ProducersList
                          maxSlidesPerPage={6}
                          logoSize={82}
                          infinite
                          slideOffset={10}
                          skipLoader
                          prefetchedProducersData={brands}
                          brandPageSlug={brandPageSlug}
                          brandPageLayout={brandPageLayout}
                        />
                      ) : null}
                    </S.CategoryDetailsBrands>
                  </S.CategoryDetailsInner>
                );
              })}

              <S.CategoryDetailsFiller aria-hidden="true" onMouseEnter={handleCategoryDetailsFillerMouseEnter} />
            </S.CategoryDetails>

            {mainContent ? <div>{mainContent}</div> : null}
          </S.CategoriesDetailsWrapper>

          <S.CategoriesNamesListSideWrapper>
            <S.CategoriesNamesList
              ref={categoriesNamesListRef}
              onMouseEnter={handleListMouseEnter}
              onMouseLeave={handleListMouseLeave}
            >
              {categories.map(({ name, slug, size }, idx) => {
                const nextLinkProps = createNextLinkProps(slug, productsPageLayout);

                return (
                  <S.CategoriesNamesListItemLink
                    name={name}
                    size={size}
                    onFocus={() => handleCategoryNameMouseOver(idx)}
                    onMouseOver={() => handleCategoryNameMouseOver(idx)}
                    key={slug}
                    nextLinkProps={nextLinkProps}
                  />
                );
              })}
            </S.CategoriesNamesList>

            {contentUnderCategories ? (
              <S.CategoriesContentUnder className={`${classNamesOfLayout.RIGHT_COLUMN}`}>
                {contentUnderCategories}
              </S.CategoriesContentUnder>
            ) : null}
          </S.CategoriesNamesListSideWrapper>
        </S.CategoriesColumnWithContentGrid>
      </S.CategoriesColumnWithContent>

      <S.Overlay displayOverlay={!isCategoryViewInactive || visibleOnHover} id="menuOverlay" />
    </>
  );
};

CategoriesColumnWithContent.defaultProps = defaultProps;
