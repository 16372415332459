import styled, { css } from 'styled-components';
import { em } from 'polished';
import { up } from 'styled-breakpoints';

const primaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultBulletListVariant('primary', props)}

  li:before {
    transform: scale(2);
    transform-origin: center center;
  }
`;

const secondaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultBulletListVariant('secondary', props)}

  li:before {
    transform: scale(2);
    transform-origin: center center;
    margin-top: ${em(1)};
  }
`;

const tertiaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultBulletListVariant('tertiary', props)}

  li:before {
    transform: scale(2);
    transform-origin: center center;
  }

  li + li {
    margin-top: 0.5em;

    ${up('tablet')} {
      margin-top: 0em;
    }
  }
`;

const quaternaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultBulletListVariant('quaternary', props)}

  li:before {
    transform: scale(2);
    transform-origin: center center;
  }

  li + li {
    margin-top: 0.5em;

    ${up('tablet')} {
      margin-top: 0em;
    }
  }
`;

export const BulletList = styled.ul`
  ${(p) => p.variant === 'primary' && primaryVariantStyles(p)};
  ${(p) => p.variant === 'secondary' && secondaryVariantStyles(p)};
  ${(p) => p.variant === 'tertiary' && tertiaryVariantStyles(p)};
  ${(p) => p.variant === 'quaternary' && quaternaryVariantStyles(p)};
`;
