import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { em, rem } from 'polished';
import BulletList from '../BulletList';
import Icon from '../Icon';
import Link from '../Link';
import { breakpointsKeys } from 'src/enums/breakpoints';
/* eslint-disable-next-line */
import { CategoriesNamesListItemLink as _CategoriesNamesListItemLink } from 'src/components/CategoriesNamesList/CategoriesNamesListItemLink';
import _Heading from 'src/components/Heading';
import _Overlay from 'src/components/Overlay';

export const CategoriesColumnWithContent = styled.div`
  ${up(breakpointsKeys.TABLET)} {
    display: block;
  }

  ${({ hoverVersion, visibleOnHover }) => {
    if (hoverVersion) {
      return css`
        ${up(breakpointsKeys.TABLET)} {
          position: absolute;
          padding-top: 1.5rem;
          z-index: 11111;
          width: 100%;
          left: calc(50vw - 50%);
          padding-left: 0.5em;
          overflow: ${() => (hoverVersion ? 'visible' : 'hidden')};
          opacity: ${() => (visibleOnHover ? 1 : 0)};
          visibility: ${() => (visibleOnHover ? 'visible' : 'hidden')};
          ${(p) => p.theme.helpers.transition(['visibility', 'opacity'], 1)};
        }

        &:before {
          pointer-events: none;
          width: 100vw !important;
          left: calc(50% - 50vw) !important;
          z-index: 111111111111;
        }
      `;
    }

    return css`
      display: initial;

      /* &:before {
        position: absolute;
        height: ${(p) => (rem(p.bgHeight) ? rem(p.bgHeight) : p.bgHeight)};
      } */
    `;
  }};
  /* &:before {
    display: none;
    ${up(breakpointsKeys.TABLET)} {
      ${(p) => p.theme.helpers.transition(['visibility', 'opacity'], 4)};
      visibility: ${(p) => (p.bgVisible ? 'visible' : 'hidden')};
      opacity: ${(p) => (p.bgVisible ? 0.25 : 0)};
      z-index: 100;

      background: ${(p) => p.theme.defaultPalette.monochrome[5]};
      content: '';
      left: 0;
      width: 100%;
      position: absolute;
      display: block;
    }
  } */
`;

export const CategoriesNamesListSideWrapper = styled.div`
  display: none;
  ${up(breakpointsKeys.DESKTOP)} {
    display: block;
    width: ${rem(312)};
    /* width: ${rem(267)}; */
    /* margin-right: ${rem(30)}; */
  }
`;

export const CategoriesNamesList = styled.ul`
  display: none;

  ${up(breakpointsKeys.DESKTOP)} {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    position: relative;
    z-index: 11;

    background: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    display: flex;
    margin-left: ${rem(-25)};
    margin-top: ${rem(-24)};
    padding-top: ${rem(25)};
    /* margin-bottom: -20px; */
    padding-left: ${rem(25)};
    padding-bottom: ${rem(25)};
    margin-bottom: ${rem(-25)};
    flex-direction: column;
    gap: ${rem(5)};
    max-width: ${rem(312)};
  }
`;

export const CategoriesNamesListItem = styled.li`
  font-family: ${(p) => p.theme.typography.type.secondary};

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 3;

  &:before {
    ${(p) => p.theme.helpers.transition(['opacity'], 4, 'default', 3)};

    background: ${(p) => p.theme.defaultPalette.monochrome[1]};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &:hover,
  &:active,
  &.active {
    cursor: 'pointer';

    &:before {
      opacity: 1;
    }
  }

  & + li {
    border-top: solid ${em(2)} ${(p) => p.theme.defaultPalette.monochrome[0]};
  }
`;

export const CategoryNameLink = styled(Link).attrs(() => ({ isNextLink: true, blank: true }))`
  color: ${(p) => p.theme.defaultPalette.prussianBlue[0]};
  padding: ${em(10)} 1.5em;
  display: block;
  flex-grow: 1;
  line-height: 1em;
  z-index: 4;
  position: relative;
`;

export const CategoryNameIcon = styled(Icon).attrs(() => ({ icon: 'arrowRight' }))`
  ${(p) => p.theme.helpers.transition(['opacity', 'transform'])};
  transform: ${(p) => `translate3d(${p.visible ? '50%' : '0%'}, 0, 0)`};

  color: ${(p) => p.theme.defaultPalette.teal[0]};
  opacity: ${(p) => (p.visible ? 1 : 0)};
  font-size: 0.5em;
  margin: 0 1.5em;
`;

export const CategoriesDetailsWrapper = styled.div`
  position: relative;
  height: ${(p) => (p.hoverVersion ? '100%' : 'auto')};
  flex-basis: 100%;
  max-width: 100%;
  flex-shrink: 0;

  ${up(breakpointsKeys.DESKTOP)} {
    flex-basis: 75%;
    max-width: 75%;
  }
`;

export const CategoryDetails = styled.div`
  ${(p) => p.theme.helpers.transition(['visibility', 'opacity'], 4)};
  visibility: ${(p) => (p.visible ? 'visible' : 'hidden')};
  opacity: ${(p) => (p.visible ? 1 : 0)};
  position: absolute;
  top: 0;
  left: ${rem(-25)};
  height: 100%;
  z-index: 10;
  display: flex;

  width: calc(100% - ${rem(30)});
  flex-direction: column;
  flex-grow: 0;
  z-index: 110;
  ${up(breakpointsKeys.DESKTOP_LARGE)} {
    width: calc(100% + ${rem(30)});
  }
`;

export const CategoryDetailsFiller = styled.div`
  flex-grow: 1;
`;

export const CategoryDetailsInner = styled.div`
  background: ${(p) => p.theme.defaultPalette.monochrome[0]};
  padding: 1.5em;
  overflow: hidden;

  ${up(breakpointsKeys.DESKTOP)} {
    position: absolute;
    display: ${(p) => (p.visible ? 'flex' : 'none')};
    min-height: ${(p) => rem(p.minHeight)};

    flex-direction: column;
    width: 100%;
    top: ${rem(-24)};

    padding: ${({ hoverVersion }) =>
      hoverVersion ? `${rem(25)} ${rem(20)} ${rem(25)} ${rem(10)}` : `${rem(25)} ${rem(20)} ${rem(25)} ${rem(5)}`};

    left: ${({ hoverVersion }) => (hoverVersion ? rem(20) : rem(25))};
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    
    height: ${(p) => (p.hoverVersion ? '100%' : 'auto')};
  }
  ${up(breakpointsKeys.DESKTOP_LARGE)} {
    padding: 25px 20px 25px 15px;
    /* padding: ${({ hoverVersion }) =>
      hoverVersion ? `${rem(25)} ${rem(20)} ${rem(25)} ${rem(15)}` : `${rem(25)} ${rem(20)} ${rem(25)} ${rem(15)}`}; */

/* left: ${({ hoverVersion }) => (hoverVersion ? rem(25) : 0)}; */
left: 0;
/* left: ${({ hoverVersion }) => (hoverVersion ? rem(15) : 0)}; */

    /* width: 100%; */
    /* width: calc(100% + ${rem(25)}); */
  }
`;

export const CategoryDetailsPrimary = styled.div`
  ${up(breakpointsKeys.DESKTOP)} {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

export const CategoryDetailsSubcategories = styled.div`
  ${up(breakpointsKeys.DESKTOP)} {
    flex-grow: 1;
  }
`;

export const CategoryDetailsBulletList = styled(BulletList).attrs(() => ({ variant: 'quaternary' }))`
  ${up(breakpointsKeys.TABLET)} {
    columns: ${({ columnsCount }) => columnsCount};

    row-gap: ${rem(15)};
    column-gap: ${rem(15)};
    width: 100%;
    padding-left: ${rem(1)};
    > li {
      /* max-width: 267px; */
      column-break-inside: avoid;
      margin-bottom: 5px;
      &::before {
        display: none;
      }
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
  ${up(breakpointsKeys.DESKTOP_LARGE)} {
    width: fit-content;
  }
`;

export const CategoryDetailsRecommendedProduct = styled.div`
  margin-top: 1.5em;

  ${up(breakpointsKeys.DESKTOP)} {
    width: ${em(275)};
    margin-left: 1.5em;
    flex-shrink: 0;
    margin-top: 0;
  }
`;

export const CategoryDetailsBrands = styled.div`
  border-top: solid ${rem(1)} ${(p) => p.theme.defaultPalette.monochrome[2]};
  margin-top: 1.5em;
  /* max-width: 600px; */
  height: 82px;
`;

export const CategoriesContentAside = styled.div`
  ${up(breakpointsKeys.DESKTOP)} {
    /* padding-left: ${rem(7)}; */
  }
`;

export const CategoriesContentUnder = styled.div`
  margin-top: ${(p) => em(p.theme.spacing[5])};
`;

export const CategoriesColumnWithContentGrid = styled.div`
  ${up(breakpointsKeys.DESKTOP)} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

export const CategoriesNamesListItemLink = styled(_CategoriesNamesListItemLink)`
  max-width: ${rem(267)};
`;

export const BulletListItemLink = styled(_CategoriesNamesListItemLink)`
  max-width: ${rem(267)};
  ${up(breakpointsKeys.DESKTOP_LARGE)} {
    width: ${rem(267)};
  }
`;

export const Heading = styled(_Heading)`
  font-size: ${({ theme }) => theme.typography.fontSize.m5};
  font-family: ${({ theme }) => theme.global.fontFamily.secondary};
`;

export const Overlay = styled(_Overlay)`
  z-index: 10;
`;
