import { productMinQuantity } from 'config/basket';

import useAddToBasketControls from 'src/hooks/useAddToBasketControls';
import useAddToBasket from 'src/hooks/useAddToBasket';
import useBasketParts from 'src/hooks/useBasketParts';
import useTranslation from 'src/hooks/useTranslation';
import useAnalytics from 'src/hooks/useAnalytics';

import BasketPartsSelection from 'src/components/BasketPartsSelection';

import * as S from './styles';

export const AddToBasketControls = ({
  className,
  id,
  name,
  category,
  step,
  price,
  brand,
  sku,
  nowInBasket,
  zeroValue,
  isPartial,
  recommendationId,
  min,
  max,
  productImgSrc,
  boughtBefore,
  ...rest
}) => {
  const { onAddToBasket, onRemoveFromBasket } = useAnalytics();
  const { t } = useTranslation();
  const [getBasketParts, { data: basketPartsData, loading: basketPartsLoading }] = useBasketParts({
    productId: id,
    onlyLocal: false,
  });
  const [addToBasket, { loading: addToBasketLoading }] = useAddToBasket({
    onCompleted: (data) => {
      const product = {
        id,
        name,
        category,
        quantity: data?.addToBasket.added?.quantity ?? 0,
        price,
        brand,
        sku,
      };
      if (data?.addToBasket.added?.quantity) {
        onAddToBasket({
          ...product,
          price: price || null,
        });
      } else {
        onRemoveFromBasket(product);
      }
    },
  });

  const {
    isProductInBasket,
    isDraftEqualToBasket,
    isDraftEqualToZeroValue,
    currentBasketQuantity,
    setDraftBasketQuantity,
    handleProductAddToBasketButtonClick,
  } = useAddToBasketControls({
    nowInBasket,
    zeroValue: productMinQuantity,
    zeroPriceValue: 0,
    isLoading: addToBasketLoading,
    isPartial,
    addToBasket,
    productId: id,
    step,
    recommendationId,
  });

  return (
    <BasketPartsSelection
      productName={name}
      productImgSrc={productImgSrc}
      basketPartsPriceSectionEntries={basketPartsData?.basketParts.productinfo}
      parts={basketPartsData?.basketParts?.items || []}
      message={basketPartsData?.basketParts?.message}
      busy={addToBasketLoading || basketPartsLoading}
      onAddToBasket={(parts, closePartSelectionModal) =>
        handleProductAddToBasketButtonClick({
          parts,
          after: () => {
            if (isPartial) {
              closePartSelectionModal();
            }
          },
        })
      }
    >
      {(openBasketPartsSelection) => {
        const fetchAndOpenPartsSelection = async () => {
          await getBasketParts();
          openBasketPartsSelection();
        };
        return (
          <>
            <S.AddToBasketButtonAndInputWrapper className={className} {...rest}>
              <S.AddToBasketInput
                variant="rounded"
                min={min}
                max={max}
                step={step}
                nowInBasket={currentBasketQuantity}
                busy={addToBasketLoading}
                onUpdate={setDraftBasketQuantity}
                showZeroPlaceholder={!isDraftEqualToBasket && isDraftEqualToZeroValue}
                onClickOverride={isPartial && currentBasketQuantity > 0 ? fetchAndOpenPartsSelection : null}
                onEnterKeyDown={async () => {
                  const basketQueryResult = await handleProductAddToBasketButtonClick({
                    parts: null,
                  });

                  const shouldDisplayPartialCalc =
                    basketQueryResult?.data.addToBasket.added?.showpartcalculator || false;

                  if (shouldDisplayPartialCalc) {
                    await fetchAndOpenPartsSelection();
                  }
                }}
              />

              <S.AddToBasketButton
                isRounded
                hideText
                forceFullOpacity
                isProductInBasket={isProductInBasket}
                isDraftEqualToZeroValue={isDraftEqualToZeroValue}
                draft={!isDraftEqualToBasket}
                wasBuyEarlier={boughtBefore}
                busy={addToBasketLoading}
                onAdd={async () => {
                  const basketQueryResult = await handleProductAddToBasketButtonClick({
                    parts: null,
                  });

                  const shouldDisplayPartialCalc =
                    basketQueryResult?.data.addToBasket.added?.showpartcalculator || false;

                  if (shouldDisplayPartialCalc) {
                    await fetchAndOpenPartsSelection();
                  }
                }}
              />
            </S.AddToBasketButtonAndInputWrapper>
            {isPartial && (
              <S.SelectPartLinkWrapper>
                <S.SelectPartLink variant="nonary" onClick={fetchAndOpenPartsSelection}>
                  {t('selectBasketPart')}
                </S.SelectPartLink>
              </S.SelectPartLinkWrapper>
            )}
          </>
        );
      }}
    </BasketPartsSelection>
  );
};
