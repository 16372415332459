import useCurrentCurrency from 'src/hooks/useCurrentCurrency';
import * as S from './styles';

import { getPriceVariant } from 'src/utils/getPriceVariantColor';
import { uiComponentVariant } from 'src/enums/uiComponentVariant';

export const PriceComponent = ({
  currencySign,
  variant = uiComponentVariant.PRIMARY,
  isFirstPriceOnTheList = false,
  separateLines,
  crossedPrice,
  unit,
  price,
  lastPrice,
  promoCode = 0,
  ...rest
}) => {
  const transformPrice = useCurrentCurrency({
    sign: currencySign,
  });
  const priceVariant = getPriceVariant(promoCode);

  return (
    <S.Price priceVariant={priceVariant} variant={variant} className="price" withLastPrice={lastPrice} {...rest}>
      <S.PriceAndCrossedPriceWrapper>
        {!!crossedPrice ? (
          <S.CrossedPrice className="crossed-price">
            <span>{transformPrice(crossedPrice)}</span>
            <S.PriceUnit>{unit}</S.PriceUnit>
          </S.CrossedPrice>
        ) : null}
        <S.PriceValue isFirstPriceOnTheList={isFirstPriceOnTheList}>
          {transformPrice(price)}/{unit}
        </S.PriceValue>
      </S.PriceAndCrossedPriceWrapper>
      {!!lastPrice ? <S.LastPriceInfo lastPrice={lastPrice} separateLines={!separateLines} /> : null}
    </S.Price>
  );
};
